
import { defineComponent, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import ContactModal from "@/components/modals/ContactModal.vue";
import DonorModal from "@/components/modals/DonorModal.vue";
import TransportModal from "@/components/modals/TransportModal.vue";

export default defineComponent({
  name: "donorfilters",
  props: {
    applied_filters: Object,
    allowed_filters: {
      type: Array,
      required: false,
      default: () => {
        return [
          "type",
          "status",
          "registration_date",
          "termination_date",
          "termination_reason",
          "id",
          "firstName",
          "lastName",
          "dni",
          "birthdate",
          "updated_at",
          "created_at",
          "address",
          "state",
          "locality",
          "transport",
          "visitor",
          "collector",
          "polygon",	
        ];
      },
    },
  },
  data() {
    return {
      terminationOptions: {},
      statusOptions: {
        active: "Activa",
        suspended: "Suspendida",
        inactive: "Inactiva",
      },
      filters: {
        type: "",
        registrationDate: [],
        terminationDate: [],
        created_at: [],
        terminationReasonId: Array<number>(),
        status: "",
        id: "",
        address_number: "",
        address: "",
        transport: "",
        string: "",
        visitorId: null,
        collectorId: null,
        firstName: "",
        lastName: "",
        dni: "",
        birthdate: "",
        localityId: null,
        stateId: null,
        schedule: null,
        updated_at: [],
        polygon: null,
      },
      collectorList: [],
      transportList: [],
      stateList: [],
      visitorList: [],
      localityList: [],
      isPendingView: false,
    };
  },
  emits: ["changed"],
  methods: {
    refreshLocalities() {
      if (this.filters.stateId) {
        ApiService.query("localities", {
          params: { stateId: this.filters.stateId },
        }).then((response) => {
          this.localityList = response.data;
        });
      }
    },
    resetFilters() {
      this.filters.type = "";
      this.filters.registrationDate = [];
      this.filters.terminationDate = [];
      this.filters.created_at = [];
      this.filters.terminationReasonId = [];
      this.filters.status = "";
      this.filters.id = "";
      this.filters.address_number = "";
      this.filters.address = "";
      this.filters.transport = "";
      this.filters.string = "";
      this.filters.visitorId = null;
      this.filters.collectorId = null;
      this.filters.firstName = "";
      this.filters.lastName = "";
      this.filters.dni = "";
      this.filters.birthdate = "";
      this.filters.localityId = null;
      this.filters.stateId = null;
      this.filters.schedule = null;
      this.filters.updated_at = [];
      this.filters.polygon = null;

      if (this.isPendingView) {
        (this.filters.terminationReasonId as any) = ["16"];
        this.filters.status = 'inactive';
      }
      this.dataChanged();
    },
    dataChanged() {
      this.$emit("changed", this.filters);
    },
  },
  async mounted() {
    watch(this.filters, () => {
      this.dataChanged();
    });
    await Promise.all([
      ApiService.query("index/users", {
        params: { permission: "place_visit", object: true },
      }).then((response) => {
        this.visitorList = response.data;
      }),
      ApiService.get("index", "collectors").then((response) => {
        this.collectorList = response.data;
      }),
      ApiService.query("index/transports", {
        params: { searchable: true }
      }).then((response) => {
        this.transportList = response.data;
      }),
      ApiService.get("index", "termination_reasons").then((response) => {
        this.terminationOptions = response.data;
      }),
      ApiService.get("index", "states").then((response) => {
        this.stateList = response.data;
      })
    ]);

    this.isPendingView = this.$route.name === "solicitudes-carga";
    (this.filters as any) = this.applied_filters;
    
    if (this.isPendingView) {
      (this.filters.terminationReasonId as any) = ["16"];
      this.filters.status = 'inactive';
      this.dataChanged();
    } else if (this.filters.terminationReasonId.includes(16) && this.filters.status === 'inactive') {
      this.filters.terminationReasonId = [];
      this.filters.status = '';
    }
    
    this.refreshLocalities();
  },
});
